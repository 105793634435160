<template>
	<div class="inviteUsersBox">
		<el-form :inline="true" :model="param" ref="param"  class="demo-form-inline">
			<el-form-item label="时间">
				<div class="time_box">
					<el-date-picker size="mini" v-model="beginTime" type="datetime" placeholder="开始时间" @change="getPickerTime('beginTime')"></el-date-picker>
					<div class="line_box">-</div>
					<el-date-picker size="mini" v-model="endTime" type="datetime" placeholder="结束时间" @change="getPickerTime('endTime')"></el-date-picker>
				</div>
			</el-form-item>
			<el-form-item>
				<el-button size="mini" type="primary" :loading="loadingBtn" @click="onSubmit('param')">查询</el-button>
				<el-button size="mini" @click="resetForm('param')">重置</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="list" v-loading="loadingShow" border style="width: 100%">
			<el-table-column prop="t_idcard" label="IDCARD"></el-table-column>
			<el-table-column prop="t_nickName" label="用户昵称"></el-table-column>
			<el-table-column prop="t_real_name" label="提现名称"></el-table-column>
			<el-table-column prop="t_nickName" label="提现方式">
				<template>
					<div>支付宝</div>
				</template>
			</el-table-column>
			<el-table-column prop="t_account_number" label="提现账号"></el-table-column>
			<el-table-column prop="put_gold" label="兑换金币(个)"></el-table-column>
			<el-table-column prop="t_money" label="提现金额(元)"></el-table-column>
			<el-table-column prop="t_handImg" label="提现二维码">
				<template slot-scope="scope">
					<div class="list_img_box">
						<img :src="scope.row.t_QR_code" alt="">
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="t_create_time" label="提现时间"></el-table-column>
			<el-table-column prop="t_handle_time" label="打款时间"></el-table-column>
			
			
			
		</el-table>

		<el-pagination background layout="total, prev, pager, next, jumper"
			:page-size="param.pageSize" 
			:current-page.sync="param.page" 
			:total="total" 
			@current-change="currentChange"></el-pagination>
		
		
		<!-- <promoteUsers :title="'推广用户'" :pageShow="dialog.promoteUsersShow" :userId="searchserId"  @close="showDialog('promoteUsersShow')"></promoteUsers>
		<IncomeDetails :title="'收益明细'" :pageShow="dialog.IncomeDetails" :userId="searchserId" @close="showDialog('IncomeDetails')"></IncomeDetails> -->
		
	</div>
</template>

<script>
	// import promoteUsers from "../components/promoteUsers.vue"
	// import IncomeDetails from "../components/IncomeDetails.vue"
	

	export default {
		components:{
			// promoteUsers,
			// IncomeDetails
		},
		data() {
			return {
				dialog:{
					promoteUsersShow:false,
					IncomeDetails:false,
				},
				param: {
					t_user_id:localStorage.getItem("t_user_id"),  //  用户Id
					t_role:1,  //  	0 用户  1主播
					searchParam:null,  //   查询Id	
					beginTime:null,  //  	开始时间
					endTime:null,  //  	结束时间
					page:1,  //   1
					pageSize:10,  //   10
				},
				searchserId:null,
				beginTime:null,  //  	开始时间
				endTime:null,  //  	结束时间
				loadingShow:false,
				loadingBtn:false,
				list: [],
				total:0
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getPickerTime(name){
				console.log("name=",name,this.beginTime)
				
				if(this[name]){
					let timeParam = this.getTime(this[name])
					let time = timeParam.year + '-' + timeParam.month + '-' + timeParam.day + ' ' + timeParam.h + ':' + timeParam.m + ':' + timeParam.s
					this.param[name] = time
					console.log("this.param",this.param)
				}else{
					this.param[name] = null
				}
				
				
			},
			async onSubmit() {
				console.log('submit!');
				this.loadingBtn = true
				await this.getList()
				this.loadingBtn = false
			},
			async getList(){
				console.log("save")
				let that = this
				let param = {
					url: "myGuildUserPutList",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					that.list = []
					if(res.status == 0){
						that.list = res.data.list
						that.total = res.data.total
					}
				})
				this.loadingShow = false
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				
				this.beginTime = null  //  	开始时间
				this.endTime = null  //  	结束时间
				this.param.beginTime = null  //  	开始时间
				this.param.endTime = null  //  	结束时间
			},
			showDialog(dialogName,item=null){
				console.log("关闭",dialogName)
				if(item !== null){
					console.log("item",item)
					this.searchserId = item.t_user_id
				}else{
					this.searchserId = null
				}
				this.dialog[dialogName] = !this.dialog[dialogName]
			},
			currentChange(page){
				this.param.page = page
				this.getList()
			}
		}
	}
</script>

<style scoped>
	.time_box {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.inviteUsersBox .line_box {
		padding: 0 5px;
		box-sizing: border-box;
	}
</style>
